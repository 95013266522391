<template>
  <div class="modal" :class="{ show: props.show }">
    <div class="container">
      <div class="mhead">
        <h3>发票信息</h3>
        <a @click="onCancel" class="close"></a>
      </div>
      <div class="mbody">
        <div class="form-group">
          <label>发票抬头</label>
          <div class="radios">
            <label
              ><input
                type="radio"
                :checked="state.type === 2"
                :value="2"
                @change="changeType"
              /><span>公司</span></label
            >
            <label
              ><input
                type="radio"
                :checked="state.type === 1"
                :value="1"
                @change="changeType"
              /><span>个人</span></label
            >
          </div>
        </div>
        <div class="form-group" v-if="state.type === 2">
          <label class="must">单位名称</label>
          <input
            type="text"
            maxlength="30"
            v-model="state.newdata.invoicCorporateName"
            placeholder="请填写单位名称"
          />
        </div>
        <div class="form-group" v-if="state.type === 2">
          <label class="must">纳税人识别号</label>
          <input
            type="text"
            maxlength="20"
            v-model="state.newdata.invoicDutyParagraph"
            placeholder="请填写纳积人识别号"
          />
        </div>
        <div class="form-group" v-if="state.type === 2">
          <label class="">单位电话</label>
          <input
            type="text"
            maxlength="20"
            v-model="state.newdata.invoicCompanyPhone"
            placeholder="可选填，格式为：区号-电话号码"
          />
        </div>
        <div class="form-group" v-if="state.type === 2">
          <label class="">单位地址</label>
          <input
            type="text"
            maxlength="30"
            v-model="state.newdata.invoicCompanyAddress"
            placeholder="可选填"
          />
        </div>
        <div class="form-group" v-if="state.type === 2">
          <label class="must">收票人邮箱</label>
          <input
            type="text"
            maxlength="50"
            v-model="state.newdata.invoicCompanyEmail"
            placeholder="用于接收电子发票"
          />
        </div>
        <div class="form-group" v-if="state.type === 1">
          <label class="must">发票抬头</label>
          <input
            type="text"
            maxlength="30"
            v-model="state.newdata.invoiceTitle"
            placeholder="请填写需要开票的姓名"
          />
        </div>
        <div class="form-group" v-if="state.type === 1">
          <label class="must">收票人邮箱</label>
          <input
            type="text"
            maxlength="50"
            v-model="state.newdata.invoicEmailbox"
            placeholder="用于接收电子发票"
          />
        </div>
      </div>
      <div class="mfoot">
        <a class="cancel" @click="onCancel">取消</a>
        <a class="submit" @click="onSubmit">保存</a>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store/index";
import { reactive, watch, onMounted } from "vue"; // computed

export default {
  props: ["show", "data"],

  setup(props, { emit }) {
    const def = {
      invoiceType: 1,
      invoiceTitle: "",
      invoicEmailbox: "",
      invoicOpeningBank: "",
      invoicCorporateName: "",
      invoicDutyParagraph: "",
      invoicAccountNumber: "",
      invoicCompanyAddress: "",
      invoicCompanyPhone: "",
      invoicCompanyEmail: "",
    };

    const state = reactive({
      type: 1,
      newdata: {},
      provinces: [],
      citys: [],
      districts: [],
    });

    watch(
      () => props.show,
      (newdata) => {
        if (newdata) {
          document.body.classList.add("modal_open");
        } else {
          document.body.classList.remove("modal_open");
        }
      }
    );

    watch(
      () => props.data,
      (newdata) => {
        // console.log("test:", props.data, def);
        if (!newdata || !newdata.invoiceType) {
          state.newdata = Object.assign({}, def);
        } else {
          const obj = Object.assign({}, def);
          Object.keys(obj).map((item) => {
            obj[item] = props.data[item];
          });
          state.type = obj.invoiceType;
          state.newdata = obj;
        }
      }
    );

    onMounted(() => {
      const newdata = props.data;
      if (!newdata || !newdata.invoiceType) {
        state.newdata = Object.assign({}, def);
      } else {
        const obj = Object.assign({}, def);
        Object.keys(obj).map((item) => {
          obj[item] = props.data[item];
        });
        console.log(obj);
        state.type = obj.invoiceType;
        state.newdata = obj;
      }
    });

    const changeType = (e) => {
      state.type = parseInt(e.currentTarget.value, 10);
      // console.log(state.type);
      state.newdata.invoiceType = state.type;
      if (state.type == 1) {
        state.newdata.invoicCorporateName = "";
        state.newdata.invoicDutyParagraph = "";
        state.newdata.invoicCompanyPhone = "";
        state.newdata.invoicCompanyAddress = "";
        state.newdata.invoicCompanyEmail = "";
      } else {
        state.newdata.invoiceTitle = "";
        state.newdata.invoicEmailbox = "";
      }
    };

    const onCancel = () => {
      emit("onCancel", {});
    };

    const onSubmit = () => {
      const type = state.type;
      const len = state.newdata.invoicDutyParagraph
        ? state.newdata.invoicDutyParagraph.length
        : 0;
      const emailReg =
        /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
      const phoneReg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;

      if (type == 1) {
        if (state.newdata.invoiceTitle === "") {
          return store.dispatch("toast", "发票抬头不能为空");
        }

        if (state.newdata.invoicEmailbox === "") {
          return store.dispatch("toast", "收票人邮箱不能为空");
        }

        if (!emailReg.test(state.newdata.invoicEmailbox)) {
          return store.dispatch("toast", "收票人邮箱不正确");
        }
      } else {
        if (state.newdata.invoicCorporateName === "") {
          return store.dispatch("toast", "单位名称不能为空");
        }

        if (len != 15 && len != 17 && len != 18 && len != 20) {
          return store.dispatch("toast", "纳税人识别号码不正确");
        }

        if (
          state.newdata.invoicCompanyPhone &&
          !phoneReg.test(state.newdata.invoicCompanyPhone)
        ) {
          return store.dispatch("toast", "公司电话不正确");
        }

        if (state.newdata.invoicCompanyEmail === "") {
          return store.dispatch("toast", "收票人邮箱不能为空");
        }

        if (!emailReg.test(state.newdata.invoicCompanyEmail)) {
          return store.dispatch("toast", "收票人邮箱不正确");
        }
      }

      console.log(state.newdata);
      emit("onSubmit", state.newdata);
    };

    return { state, props, changeType, onCancel, onSubmit };
  },
};
</script>

<style lang="less" scoped>
@import "./modal.less";
@import "../../assets/less/form.less";

.form-group {
  padding: 10px 0px;

  input[type="text"],
  input[type="number"],
  textarea {
    width: 300px;
    padding: 0 12px;
  }
}
</style>
