<template>
  <div id="cartsubmit">
    <tmpl_header :hide="['search', 'nav', 'shopcart']" subtitle="结算页" />

    <cart_steps :step="2"></cart_steps>

    <div class="settle">
      <section class="card address">
        <div class="title">
          <h3>收货信息</h3>
          <a class="opts" @click="createAddr">新增收货地址</a>
        </div>
        <div class="list">
          <ul>
            <li
              v-for="(addr, i) in address"
              :key="addr.addressId"
              :class="{ default: '1' == addr.isDefault, sel: i == selAddr }"
              :style="i > 3 && !moreAddr ? 'display:none' : ''"
              :data-i="i"
              @click="selectAddress"
            >
              <p>
                <b>{{ addr.addressName }}</b
                >{{ addr.addressMoblie
                }}<i class="edit" :data-i="i" @click="editAddr"></i>
              </p>
              <p>
                {{ addr.province.province_name }} {{ addr.city.city_name }}
                {{ addr.district.district_name }} {{ addr.addressDetail }}
              </p>
              <i>默认地址</i>
            </li>
            <li v-if="!address || address.length == 0" class="empty">
              <p>请新增地址</p>
            </li>
          </ul>
          <div
            v-if="address && address.length > 4"
            class="more"
            :class="{ up: moreAddr }"
            @click="moreAddress"
          >
            {{ moreAddr ? "收起" : "更多地址" }}
          </div>
        </div>
      </section>

      <section class="card products">
        <div class="title">
          <h3>商品信息</h3>
          <router-link to="/cart" class="opts">返回修改购物车</router-link>
        </div>
        <div class="list">
          <ul>
            <li class="thead">
              <div class="name">商品信息</div>
              <div class="attrs">规格</div>
              <div class="price">单价(元)</div>
              <div class="num">数量</div>
              <div class="total">合计</div>
            </li>
            <li class="tr" v-for="pro in products" :key="pro.shoppingCartId">
              <div class="name">
                <img :src="pro.productMap.goodsInfoImgId" />
                <p>{{ pro.productMap.goodsInfoName }}</p>
              </div>
              <div class="attrs">--</div>
              <div class="price">
                ¥{{ pro.productMap.goodsInfoPreferPrice }}
              </div>
              <div class="num">{{ pro.goodsNum }}</div>
              <div class="total">¥{{ formatPrice(pro.total) }}</div>
            </li>
          </ul>
        </div>
      </section>

      <section class="card paytype">
        <div class="title">
          <h3>支付方式</h3>
        </div>
        <div class="list">
          <ul>
            <!--li :class="{ sel: payType == 1 }" @click="selectPayType(1)">
              预付款
            </li-->
            <li :class="{ sel: payType == 2 }" @click="selectPayType(2)">
              在线支付
            </li>
            <!--li :class="{ sel: payType == 3 }" @click="selectPayType(3)">
              货到付款
            </li-->
          </ul>
        </div>
      </section>

      <section class="card invoice">
        <div class="title">
          <h3>发票信息</h3>
          <a class="opts" @click="showInvoice">修改</a>
        </div>
        <div class="list">
          <ul>
            <li v-if="!invoice.invoiceType">
              <label>是否需要发票</label>
              <b>否</b>
            </li>
            <template v-if="invoice.invoiceType">
              <li>
                <label>是否需要发票</label>
                <b>是</b>
              </li>
              <li>
                <label>发票抬头</label>
                <b>{{ invoice.invoiceTitle }}</b>
              </li>
              <li>
                <label>开票类型</label>
                <b>普通发票</b>
              </li>
              <li>
                <label>开票方式</label>
                <b>电子发票</b>
              </li>
              <li>
                <label>发票内容</label>
                <b>需要发票明细</b>
              </li>
            </template>
          </ul>
        </div>
      </section>

      <section class="card submit">
        <div class="addr">
          <ul>
            <li class="">
              <label>寄送至</label
              ><b v-if="selectAddr.addressId">
                {{ selectAddr.province.province_name }}
                {{ selectAddr.city.city_name }}
                {{ selectAddr.district.district_name }}
                {{ selectAddr.addressDetail }}</b
              >
            </li>
            <li class="">
              <label>收件人</label><b>{{ selectAddr.addressName }}</b>
            </li>
            <li class="">
              <label>联系电话</label><b>{{ selectAddr.addressMoblie }}</b>
            </li>
          </ul>
        </div>
        <div class="price">
          <ul>
            <li class="detail">
              <label>共{{ count }}件商品，商品总价</label
              ><b>¥{{ formatPrice(total) }}</b>
            </li>
            <li class="detail"><label>运费</label><b>¥0.00</b></li>
            <li class="total">
              <label>应付总额</label><b>¥{{ formatPrice(total) }}</b>
            </li>
          </ul>
          <a class="btn" @click="submit">提交订单</a>
        </div>
      </section>
    </div>

    <modal_address
      :show="showAddressModal"
      :data="pickAddr"
      @onCancel="showAddressModal = false"
      @onSubmit="savedAddress"
    />

    <modal_invoice
      :show="showInvoiceModal"
      :data="invoice"
      @onCancel="showInvoiceModal = false"
      @onSubmit="savedInvoice"
    />
    <tmpl_footer style="margin-top: 0" />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import cart_steps from "./_steps.vue";
import modal_address from "../../components/modal/address.vue";
import modal_invoice from "../../components/modal/invoice.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
      address: [],
      products: [],
      selAddr: -1,
      selectAddr: {},
      total: 0,
      count: 0,
      checked: [],
      productId: null,
      num: 0,
      payType: 2,

      moreAddr: false,
      showAddressModal: false,
      pickAddr: {},
      showInvoiceModal: false,
      invoice: {},
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    cart_steps,
    modal_address,
    modal_invoice,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({ path: "/signin", query: { to: encodeURI("/cart") } });

    const productId = this.$route.query.productId;
    if (productId) {
      this.productId = productId;
      this.num = parseInt(this.$route.query.num, 10);

      api.all([this.loadProduct(), this.loadAddress()]).then(
        api.spread(() => {
          this.loading = false;
        })
      );
    } else {
      const ids = sessionStorage.getItem("checkedCartIds");
      if (!ids) this.$router.push({ path: "/cart" });

      this.checked = JSON.parse(ids);

      api.all([this.loadCart(), this.loadAddress()]).then(
        api.spread(() => {
          this.loading = false;
        })
      );
    }
  },

  methods: {
    loadProduct: function () {
      return api
        .post(apis.goods.detail, { productId: this.productId })
        .then((res) => {
          this.products = [
            {
              // customerId: 1,
              // delFlag: "0",
              // distinctId: 1,
              // fitId: null,
              // goodsGroupId: null,
              goodsInfoId: res.productDetail.goodsInfoId,
              goodsNum: this.num,
              goodsPrePrice: null,
              goodsPrice: null,
              marketingActivityId: null,
              marketingId: null,
              orderMarketingId: null,
              presentScopeIds: null,
              productMap: {
                goodsInfoId: res.productDetail.goodsInfoId,
                goodsInfoImgId: res.productDetail.goodsInfoImgId,
                goodsInfoName: res.productDetail.goodsInfoName,
                goodsInfoPreferPrice: res.productDetail.goodsInfoPreferPrice,
              },
              shoppingCartId: 0,
              wareStock: res.stock,
              total:
                Math.round(res.productDetail.goodsInfoPreferPrice * 100) *
                this.num,
            },
          ];

          this.total = this.products[0].total;
          this.count = this.num;
        });
    },

    loadCart: function () {
      return api.post(apis.common.shoppingCart, {}).then((res) => {
        this.products = res.filter((item) => {
          item.total =
            Math.round(item.productMap.goodsInfoPreferPrice * 100) *
            item.goodsNum;

          if (this.checked.indexOf(item.shoppingCartId) !== -1) {
            this.total += item.total;
            this.count += item.goodsNum;
          }
          return this.checked.indexOf(item.shoppingCartId) !== -1;
        });
      });
    },

    loadAddress: function () {
      return api.post(apis.center.address, {}).then((res) => {
        this.address = res;
        if (res.length) {
          if (!this.selectAddr.addressId) {
            res.map((item, i) => {
              if (item.isDefault == "1") {
                this.selAddr = i;
                this.selectAddr = item;
              }
            });
          } else {
            const id = this.selectAddr.addressId;
            this.selAddr = -1;
            this.selectAddr = {};

            res.map((item, i) => {
              if (item.addressId === id) {
                this.selAddr = i;
                this.selectAddr = item;
              }
            });
          }
        }
      });
    },

    formatPrice(price) {
      let fen = price % 100;
      return Math.floor(price / 100) + "." + (fen < 10 ? "0" : "") + fen;
    },

    moreAddress() {
      this.moreAddr = !this.moreAddr;
    },

    createAddr() {
      this.pickAddr = {};
      this.showAddressModal = true;
    },

    editAddr(e) {
      var i = e.currentTarget.dataset.i;
      this.pickAddr = this.address[i];
      this.showAddressModal = true;
    },

    savedAddress() {
      this.loadAddress();
      this.pickAddr = {};
      this.showAddressModal = false;
    },

    selectAddress(e) {
      var i = (e.currentTarget && e.currentTarget.dataset.i) || -1;
      if (i < 0) return;
      this.selAddr = i;
      this.selectAddr = this.address[i];
    },

    showInvoice() {
      this.showInvoiceModal = true;
    },

    savedInvoice(e) {
      console.log(e);
      this.invoice = e;
      this.showInvoiceModal = false;
    },

    selectPayType(pay) {
      this.payType = pay;
    },

    submit() {
      if (!this.selectAddr.addressId) {
        return store.dispatch("toast", "请选择收货地址");
      }

      var params = {
        custAddress: this.selectAddr.addressId,
        customerRemark: "",
        invoicAccountNumber: this.invoice.invoicAccountNumber || "",
        invoicCompanyAddress: this.invoice.invoicCompanyAddress || "",
        invoicCompanyEmail: this.invoice.invoicCompanyEmail || "",
        invoicCompanyPhone: this.invoice.invoicCompanyPhone || "",
        invoicCorporateName: this.invoice.invoicCorporateName || "",
        invoicDutyParagraph: this.invoice.invoicDutyParagraph || "",
        invoicEmailbox: this.invoice.invoicEmailbox || "",
        invoicOpeningBank: this.invoice.invoicOpeningBank || "",
        invoiceTitle: this.invoice.invoiceTitle || "",
        invoiceType: this.invoice.invoiceType || "0",
        payflag: this.payType,
        presentScopeIds: "",
        shoppingCartIds: [],
        // productId: 51,
        // goodsNum: 1,
        typeflag: 0,
      };

      if (this.productId) {
        params.productId = this.productId;
        params.goodsNum = this.num;
      } else {
        params.shoppingCartIds = this.checked;
      }

      api.post(apis.order.submit, params).then((res) => {
        console.log(res);
        const orderId = res[0].orderId;
        this.$router.push({
          path: "/cart/pay",
          query: { order: orderId },
        });
      });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./submit.less");
</style>
